module.exports = {
  title: `Sarwech Shar`,
  description: `I talk about finding ideas for side projects, launching a bootstrapped business and finding customers.`,
  author: `Sarwech Shar`,
  introduction: `👋 I’m Sarwech, a self-taught developer building web apps and websites. Before learning to code, I was a lawyer then an analyst then a growth marketer.

I'm building Nocodelytics (analytics for no-coders) and Space Bandits.

Feel free to reach out by email or find me on Twitter :).`,
  siteUrl: `https://sarwech-blog.netlify.com`, // Your blog site url
  social: {
    twitter: ``, // Your Twitter account
    github: ``, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: ``, // Your LinkedIn account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'sarwech/my-blog', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: 'spacebandits',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: '', // Add your google analytics tranking ID
}
